﻿.CourseContent {
    border: solid 2px #eee;
    @extend .px-3;
    @extend .pb-1;
    @extend .pt-3;
    overflow: hidden;
    background-color: #fff;

    em {
        font-style: italic;
    }

    strong, b {
        font-weight: bold;
        color: #24435D;
    }

    blockquote {
        margin: 0 3em 0.8em 3em;
        padding: 0;
    }

    table {
        margin: 0 0 0.8em 0;
        padding: 0;
        border-collapse: collapse;
        background-color: #ECF1EF;
        @extend .clearfix;

        td, th {
            padding: 4px;
            vertical-align: top;
            border: solid 2px #fff;
        }

        th {
            text-align: center;
            font-weight: bold;
        }
    }


    h1 {
        color: #000099;
        font-size: 2rem;
        font-weight: bold;
        line-height: 2rem;
        text-align: left;

        &:first-child {
            @extend .mt-0;
        }
    }

    h2 {
        color: #000099;
        font-size: 1.75rem;
        font-weight: bold;
        @extend .mt-3;
        @extend .mb-2;
        padding: 0 0 2px 0;
        text-align: left;
        width: auto;
        border-bottom: solid 2px #36648B;

        &:first-child {
            @extend .mt-0;
        }
    }

    h3 {
        color: #000099;
        font-size: 1.5rem;
        font-weight: bold;
        @extend .mt-3;
        @extend .mb-2;
        padding: 0 0 2px 0;
        text-align: left;
        width: auto;
        border-bottom: solid 2px #36648B;

        &:first-child {
            @extend .mt-0;
        }
    }

    > div > h1, > div > h2, > div > h3 {
        margin-top: 0;
    }

    h4 {
        font-size: 1rem;
        font-weight: bold;
        @extend .mt-3;
        @extend .mb-2;
        padding: 0 0 2px 0;
        text-align: left;

        &:first-child {
            @extend .mt-0;
        }
    }

    @include media-breakpoint-up(sm) {
        img {
            max-width: 50%;
            height: auto;

            &.left {
                display: block;
                float: left;
                margin: 0 0.5rem 0.5rem 0;
            }

            &.right {
                display: block;
                float: right;
                margin: 0 0 0.5rem 0.5rem;
            }

            &.left, &.right {
                &:after {
                    visibility: hidden;
                    display: block;
                    font-size: 0;
                    content: " ";
                    clear: both;
                    height: 0;
                }
            }
        }
    }
    /* mobile adjustments */
    @include media-breakpoint-down(sm) {
        img {
            display: block !important;
            margin-top: 0.5rem;
            bottom: 0.5rem;
            max-width: 100%;
        }
    }
}
