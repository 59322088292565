﻿h1 {
    margin-top: 0.5rem;
    color: #333300;
    border-bottom: solid thin #333300;
    margin-bottom: 1rem;
    font-size: 2rem;
}

.navbar-logo {
    background: url('/images/logo-mobile.png') no-repeat;
    width: 176.5px;
    height: 40px;
    background-size: contain;
}

.show-server-1 {
    display: block;
}

.show-server-2 {
    display: none;
}

// alerts
.AlertList {
    border-collapse: collapse;
    vertical-align: top;

    .AlertWarning, .AlertError, .AlertCritical {
        color: red;

        A {
            color: red;
            text-decoration: none;
        }

        &:hover a {
            color: #000;
        }
    }

    .AlertValue {
        text-align: right;
        min-width: 20px;
    }

    .AlertText {
        text-align: left;
        padding-left: 6px;
    }
}

.history {
    @extend .mb-4;

    .history-item {
        @extend .mb-1;
        background-color: $gray-200;
        border: solid 1px #ccc;

        .history-r1 {
            @extend .p-1;
        }

        .history-date {
            @extend .col-sm-8;
        }

        .history-emp {
            @extend .col-sm-4;
            @extend .text-end;
            @extend .text-secondary;
        }

        .history-r2 {
            background-color: $gray-100;
            @extend .p-1;
        }

        .history-desc {
            @extend .col;
        }

        &.history-item-admin .history-r2 {
            background-color: rgb(175, 238, 238);
        }

        &.history-item-note .history-r2 {
            background-color: rgb(250, 250, 210);
        }
    }
}

/* Tables */
.table {
    .table-footer {

        .foot-summary {
            font-weight: bold;
            border-top: solid 1px black;
            border-bottom: double 3px black;
            text-align: right;
        }
    }
    // how we disable items in a table
    .disabled, .disabled td, .disabled td a {
        color: #a9a9a9;
        font-style: italic;
    }
}
