﻿.formerror {
    color: #8B0000;
}

@media print {
    .TopRow3, .TopRowLeft3, .MenuTD, .BottomRow {
        display: none;
    }

    .MiddleRow, .OuterTable {
        background-image: none;
    }

    BODY {
        background-color: White;
    }
}

/***********************************************
** Data Grid
***********************************************/

.dgTable {
    font-family: Arial, Helvetica, sans-serif;
    table-layout: fixed;
    border: solid 1px #ccc;

    .dgHead {
        font-size: 12px;
        color: #3D3DB6;
        background-color: #E8EBFD;
        font-weight: bold;
    }

    .dgHead TD, .dgHead TH, .dgItem TD, .dgItemAlt TD, .dgFoot TD {
        padding: 1px 4px 1px 4px;
        font-size: 12px;
    }

    .dgSubHead {
        font-size: 12px;
        color: black;
        background-color: #EEE;
        font-weight: bold;
    }

    .dgSubHead A {
        font-weight: normal;
    }

    .dgHeadSort {
        color: Black;
        font-style: italic;
    }

    .dgFoot {
        color: #3D3DB6;
        background-color: white;
        font-weight: bold;
    }

    .dgItem {
        background-color: #F2F2F2;
    }

    .dgSelect {
        font-size: 12px;
        background-color: #FFFFCC;
    }

    .dgFootSum {
        color: black;
        background-color: white;
        font-weight: bold;
        border-top: solid 1px black;
        border-bottom: double 3px black;
        text-align: right;
    }

    .dgItemAlt {
        background-color: white;
    }

    .dgLink, TD.dgLink A {
        font-size: 12px;
        text-decoration: none;
        text-align: center;
    }

    .dgLinkSmall, TD.dgLinkSmall A {
        font-size: 10px;
        text-decoration: none;
        text-align: center;
    }

    A.dgLinkHide, .dgLinkHide, TD.dgLinkHide A {
        color: black;
        text-decoration: none;
    }

    A.dgLinkHide:hover, .dgLinkHide, TD.dgLinkHide A:hover {
        color: red;
        text-decoration: underline;
    }

    TD.dgButton {
        text-align: center;
    }

    TD.dgButton INPUT {
        font-size: 10px;
    }

    TD.dgInput INPUT {
        font-size: 10px;
    }
}


.dgPager {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    background-color: white;
    font-weight: bold;
}


.dgHeading1 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12pt;
    color: black;
    font-weight: bold;
}

.dgHeading2 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 10pt;
    color: black;
    font-weight: bold;
}

.dgEllipsis {
    overflow: hidden;
    white-space: nowrap;
    TEXT-OVERFLOW: ellipsis;
}

.dgEllipsisSmall {
    overflow: hidden;
    white-space: nowrap;
    TEXT-OVERFLOW: ellipsis;
    font-size: 10px;
}

TD.dgBeforeList {
    padding-bottom: 4px;
    border-bottom: solid 1px #cccccc;
}

TD.dgList {
    padding-top: 4px;
}

/****************************************************************************
** Filter
****************************************************************************/

.filtercaption {
    font-size: 11px;
    font-family: Arial, Helvetica, sans-serif;
    padding-right: 8px;
}

.filterfield {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    margin-right: 8px;
}

.filterbutton {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 10px;
    margin-right: 8px;
}

.filterbox {
    border-right: #dddddd 2px solid;
    border-top: #dddddd 2px solid;
    padding-left: 2px;
    padding-bottom: 2px;
    border-left: #dddddd 2px solid;
    padding-top: 2px;
    border-bottom: #dddddd 2px solid;
}

/****************************************************************************
** forms
****************************************************************************/

.formtable {
    background-color: #eee; /* border-collapse:collapse; */
    font-family: Arial, Helvetica, sans-serif; /* border: solid 1px black;*/
    font-size: 13px;
    border-collapse: collapse;

    TD {
        padding: 2px;
    }


    TD.formsubhead {
        color: #00008B;
        font-weight: bold;
        padding: 2px;
        background-color: #ccc;
        text-align: center;
    }


    .formspacer {
        height: 12px;
    }
}

.formtable, .formtableinner {

    .formcaption {
        text-align: right;
        color: #00008B;
        padding-right: 4px;
    }

    .formcaptionreq {
        text-align: right;
        color: #00008B;
        padding-right: 4px;
        font-weight: bold;
    }
}

.formtableinner {
    background-color: #e5e5e5;
    margin-left: auto;
    margin-right: auto;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
}




.formdata {
    font-weight: bold;

    input, SELECT {
        font-weight: normal;
    }
}



TD.formdatanormal {
}

TD.formhead {
    font-weight: bold;
    color: #fff;
    background-color: #000;
    font-size: 13px;
    padding: 4px;
    text-align: center;
}


TABLE.formtableinner TD.formsubhead {
    color: #00008B;
    text-align: center;
}


.formbutton {
    font-size: 11px;
}

td.formbuttonarea {
    text-align: right;
    vertical-align: top;
    font-size: 12px;
}

.formbuttonarea INPUT[type='button'], .formbuttonarea INPUT[type='submit'] {
    font-size: 12px;
    min-width: 100px;
}

.formbuttonarearight {
    text-align: center;
    vertical-align: top;

    INPUT[type='button'], INPUT[type='submit'] {
        width: 95%;
        font-size: 12px;
    }
}

.formsubheadexplain {
    font-size: 10px;
    color: Black;
    font-weight: normal;
}


/***********************************************
** tabs
***********************************************/

.tabmenu {
    margin: 0 0 0 0px;
    height: 23px;
    padding: 0;

    ul {
        margin: 0;
        padding: 0;

        li {
            margin: 0;
            padding: 0;
            display: inline;
            list-style: none;
            border: none;
        }
    }

    a {
        &:hover {
            color: #fff;
            background: #333;
        }

        &:link, &:visited {
            background-color: #999;
            color: #FFF;
            border: 1px solid #CCC;
            border-bottom: 1px solid #999;
        }
    }

    ul.innertabs a:link, ul.innertabs a:visited {
        float: left; /* for IE6 */
        font-size: 12px;
        font-weight: normal;
        padding: 2px 8px 4px 8px;
        margin: 0;
    }

    ul.innertabs a:link.current, ul.innertabs a:visited.current {
        background: #e5e5e5 top repeat-x;
        border-bottom: 1px solid #e5e5e5;
        color: #222;
        font-weight: bold;
        text-decoration: none;
        margin: 0;
    }

    ul.innertabs a:link.current, ul.innertabs a:visited.current {
        color: #333;
    }

    a:hover {
        color: #000;
        background-color: #FFF;
        border-bottom: 1px solid #FFF;
    }

    li#AdminTab a:link, li#AdminTab a:visited {
        color: #999;
        background-color: #CCC;
        border-bottom: 1px solid #CCC;
        text-decoration: underline;
    }

    li#AdminTab a:hover {
        color: #444;
    }

    li#AdminTab a.current {
        color: #444;
        text-decoration: none;
    }
}

/***********************************************
**Sundry
***********************************************/
.studentstatusbox {
    color: white;
    width: 80px;
    font-weight: bold;
    padding: 2px 0px;
    text-align: center;
}

.studentstatusspacer {
    width: 10px;
}

